import { render, staticRenderFns } from "./DataAnalytics.vue?vue&type=template&id=78cc41f6&scoped=true"
import script from "./DataAnalytics.vue?vue&type=script&setup=true&lang=js"
export * from "./DataAnalytics.vue?vue&type=script&setup=true&lang=js"
import style0 from "./DataAnalytics.vue?vue&type=style&index=0&id=78cc41f6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78cc41f6",
  null
  
)

export default component.exports