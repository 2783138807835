<template>
    <div class="about">
        <!-- <div class="col-md-6 col-lg-4"> -->
            <div class="card h-100">
                <div
                    class="card-body d-flex flex-column justify-content-between p-4 p-lg-5"
                >
                    <h1>About ShiftMinds Technology Solutions</h1>
                    <p>
                        ShiftMinds Technology Solutions is a leading technology solutions provider
                        specializing in digital transformation, innovation, and cutting-edge
                        technology services. Our mission is to help businesses embrace the
                        potential of the digital revolution by leveraging the latest technologies
                        and methodologies to drive growth, improve efficiency, and enhance
                        customer experiences.
                    </p>
                    <h2>Our Team</h2>
                    <p>
                        Our team consists of experienced professionals with diverse backgrounds in
                        technology, design, and business strategy. We are passionate about
                        staying ahead of the curve and continuously exploring new technologies and
                        trends to provide the best solutions for our clients. We pride ourselves
                        on our ability to work closely with clients to understand their unique
                        needs and deliver tailor-made solutions that exceed expectations.
                    </p>
                    <h2>Our Approach</h2>
                    <p>
                        At ShiftMinds Technology Solutions, we believe in a collaborative and
                        agile approach to problem-solving. By working closely with our clients and
                        maintaining open lines of communication, we are able to gain a deep
                        understanding of their specific challenges and goals. Our iterative
                        process allows us to quickly adapt to changes and incorporate feedback,
                        ensuring that our solutions are both effective and flexible.
                    </p>
                    <h2>Why Choose Us?</h2>
                    <p>
                        When you choose ShiftMinds Technology Solutions, you're choosing a
                        partner that is dedicated to your success. We understand the importance of
                        staying ahead in today's fast-paced digital landscape, and we are
                        committed to providing innovative, reliable, and cost-effective solutions
                        to help your business thrive. With our expert team, customer-focused
                        approach, and dedication to excellence, we are confident that we can help
                        you achieve your digital transformation goals and position your business
                        for long-term success.
                    </p>
                </div>  
            </div>
        <!-- </div> -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'About',
  };
  </script>
  
  <style scoped>
  .about {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .about h1,
  .about h2 {
    margin-top: 30px;
  }
  </style>
  