<template>
  <div class="blog">
    <section class="blog">
      <div class="container">
        <div class="section-title text-center">
          <h1 class="mb-3 mb-md-4">
            <span class="text-primary">Blog</span>
          </h1>
          <h2 class="w-75 mx-auto mb-3 mb-md-4">Latest Updates</h2>
          <p class="w-50 mx-auto mb-0">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos quia
            accusamus dolor quisquam animi fugiat iure.
          </p>
        </div>
        <div class="row">
          <div class="col-lg-6 mx-auto">
            <form class="d-flex justify-content-center gap-3">
              <div class="position-relative w-100">
                <div
                  class="position-absolute d-flex align-items-center top-0 bottom-0 left-0"
                  style="z-index: 10; padding: 1.35rem"
                >
                  <i class="far fa-envelope fa-lg"></i>
                </div>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="Email Address"
                  style="padding-left: 3.75rem !important"
                />
              </div>
              <button type="submit" class="btn btn-primary btn-lg">Subscribe</button>
            </form>
          </div>
        </div>
      </div>
    </section>
    <section class="blog pt-0">
      <div class="container">
        <div class="d-flex position-relative">
          <div class="row g-3">
            <div class="col-md-6">
              <div class="card h-100">
                <div class="card-body p-3 p-md-4 p-lg-5">
                  <div class="small text-muted mb-3">January 25, 2023</div>
                  <h5 class="text-dark fw-bold mb-3 mb-md-4">
                    Lorem ipsum, dolor sit amet
                  </h5>
                  <p class="mb-3 mb-md-4 mb-lg-5">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facere
                    aliquid eos earum facilis fugiat nulla, molestiae quod ipsam placeat
                    natus illo magni quidem sit ratione ipsa ea consectetur praesentium
                    dolores? Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit aspernatur in, recusandae dignissimos inventore quam sint
                    soluta commodi unde vitae quae possimus ullam explicabo esse iusto
                    ipsa animi ducimus ...
                  </p>
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card h-100">
                <div class="card-body p-3 p-md-4 p-lg-5">
                  <div class="small text-muted mb-3">January 25, 2023</div>
                  <h5 class="text-dark fw-bold mb-3 mb-md-4">
                    Lorem ipsum, dolor sit amet
                  </h5>
                  <p class="mb-3 mb-md-4 mb-lg-5">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facere
                    aliquid eos earum facilis fugiat nulla, molestiae quod ipsam placeat
                    natus illo magni quidem sit ratione ipsa ea consectetur praesentium
                    dolores? Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit aspernatur in, recusandae dignissimos inventore quam sint
                    soluta commodi unde vitae quae possimus ullam explicabo esse iusto
                    ipsa animi ducimus ...
                  </p>
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card h-100">
                <div class="card-body p-3 p-md-4 p-lg-5">
                  <div class="small text-muted mb-3">January 25, 2023</div>
                  <h5 class="text-dark fw-bold mb-3 mb-md-4">
                    Lorem ipsum, dolor sit amet
                  </h5>
                  <p class="mb-3 mb-md-4 mb-lg-5">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facere
                    aliquid eos earum facilis fugiat nulla, molestiae quod ipsam placeat
                    natus illo magni quidem sit ratione ipsa ea consectetur praesentium
                    dolores? Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit aspernatur in, recusandae dignissimos inventore quam sint
                    soluta commodi unde vitae quae possimus ullam explicabo esse iusto
                    ipsa animi ducimus ...
                  </p>
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card h-100">
                <div class="card-body p-3 p-md-4 p-lg-5">
                  <div class="small text-muted mb-3">January 25, 2023</div>
                  <h5 class="text-dark fw-bold mb-3 mb-md-4">
                    Lorem ipsum, dolor sit amet
                  </h5>
                  <p class="mb-3 mb-md-4 mb-lg-5">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facere
                    aliquid eos earum facilis fugiat nulla, molestiae quod ipsam placeat
                    natus illo magni quidem sit ratione ipsa ea consectetur praesentium
                    dolores? Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Impedit aspernatur in, recusandae dignissimos inventore quam sint
                    soluta commodi unde vitae quae possimus ullam explicabo esse iusto
                    ipsa animi ducimus ...
                  </p>
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import "@/assets/css/blog.css";

export default {
  data: () => ({}),

  name: "Blog",
};
</script>
